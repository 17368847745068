import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button } from '@popmenu/common-ui';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { createEvent } from '~/utils/eventable';
import { formatCurrency } from '../../../../utils/currency';
import { withRestaurant } from '../../../../utils/withRestaurant';
import { setGiftCardId, setProfileEventTracking } from '../../../../shared/ConsumerActions';
import { useApplyGiftCardByIdMutation } from '../../../../libs/gql/mutations/menu_item_carts/applyGiftCardByIdMutation.generated';
import { useSnackbar } from '../../../../utils/withSnackbar';

import GiftCardsDetails from './GiftCardsDetails';
import BasicModal from '../../../../admin/shared/BasicModal';
import { profileCardsStyles } from '../../profile.styles';
import { AH } from '../../../shared/AccessibleHeading';
import { useOrderCartUrl, useCloseProfile, useIsOrderingApp, useSessionCart } from '../../hooks';

const useStyles = makeStyles(profileCardsStyles);

const useMessages = () => {
  const intl = useIntl();
  return {
    giftCardApplied: intl.formatMessage({ defaultMessage: 'Card Applied to Cart', id: 'profile.gift_card_applied' }),
    lastFour: lastFour => intl.formatMessage({ defaultMessage: '**** **** **** {lastFour}', id: 'profile.profile_gift_card_code' }, { lastFour }),
    redeemNow: intl.formatMessage({ defaultMessage: 'Redeem Now', id: 'models.guest_profile.offer.redeem_now' }),
    seeDetails: intl.formatMessage({ defaultMessage: 'See Details', id: 'models.guest_profile.offer.see_details' }),
    value: value => intl.formatMessage({ defaultMessage: 'Current Value: {value}', id: 'profile.profile_gift_card_value' }, { value }),
  };
};

const GiftCardsCard = ({ giftCard }) => {
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const messages = useMessages();
  const { showSnackbar, showSnackbarError } = useSnackbar();
  const orderCartUrl = useOrderCartUrl();
  const closeProfile = useCloseProfile();
  const isOrderingApp = useIsOrderingApp();
  const [applyGiftCardById] = useApplyGiftCardByIdMutation();
  const { cart } = useSessionCart();

  const profileEventTracking = useSelector(state => state.consumer.profileEventTracking);

  const handleRedeemNow = () => {
    if (isOrderingApp) {
      applyGiftCardById({
        onCompleted: () => {
          closeProfile();
          showSnackbar(messages.giftCardApplied);
        },
        onError: error => showSnackbarError(error),
        variables: {
          giftCardId: giftCard.id,
          menuItemCartId: cart?.id,
        },
      });
    } else {
      createEvent({
        eventableType: 'GuestProfile',
        eventLabel: 'profile | giftcards | redeem_now_button | redeem_now',
        eventType: 'profile_giftcards_redeem_now_click',
      });
      dispatch(setGiftCardId(giftCard.id));
      if (!profileEventTracking.some(event => event === 'gift_card')) {
        dispatch(setProfileEventTracking([...profileEventTracking, 'gift_card']));
      }
      history.push(orderCartUrl());
    }

    closeProfile();
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        flexDirection="column"
        border={1}
        borderRadius="borderRadius"
        borderColor="gray.dark"
        justifyContent="center"
        alignItems="center"
        padding={1.5}
      >
        {giftCard.imageUploadedPhoto && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            paddingBottom={1.5}
            overflow="hidden"
          >
            <img
              src={giftCard.imageUploadedPhoto.thumbnailUrl}
              alt={giftCard.imageUploadedPhoto.originalFilename}
              className={classes.cardImg}
            />
          </Box>
        )}
        <Box marginBottom={1}>
          <AH typography variant="h5" align="center">
            {messages.lastFour(giftCard.lastFour)}
          </AH>
        </Box>
        <Box fontWeight={600}>
          <Typography>
            {messages.value(formatCurrency(giftCard.amount, undefined, { showDecimals: true, showSymbol: true }))}
          </Typography>
        </Box>
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          marginTop={2}
        >
          <Box width="50%" paddingRight={1}>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => setShowDetailsModal(true)}
            >
              {messages.seeDetails}
            </Button>
          </Box>
          <Box width="50%" paddingLeft={1}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              style={{ padding: '6px 16px' }}
              onClick={handleRedeemNow}
            >
              {messages.redeemNow}
            </Button>
          </Box>
        </Box>
      </Box>
      <BasicModal
        closeModal={() => setShowDetailsModal(false)}
        show={showDetailsModal}
        size="sm"
        title="Gift Card Details"
      >
        <GiftCardsDetails
          giftCard={giftCard}
        />
      </BasicModal>
    </React.Fragment>
  );
};

GiftCardsCard.propTypes = {
  giftCard: PropTypes.object.isRequired,
};

export default withRestaurant(GiftCardsCard);
